import Swiper from 'swiper'

import { FreeMode, Mousewheel, Navigation, Pagination } from 'swiper/modules'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/mousewheel'

export default class Carousel {
  constructor(selector, options = {}) {
    const elements = document.querySelectorAll(selector)

    elements.forEach((element) => {
      const navigation = {
        nextEl: element.querySelector('.swiper-nav.is-next'),
        prevEl: element.querySelector('.swiper-nav.is-prev'),
      }

      this.instance = new Swiper(element, {
        modules: [Navigation, Pagination, Mousewheel, FreeMode],
        mousewheel: {
          forceToAxis: true,
        },
        navigation,
        ...options,
      })
    })
  }
}
